@import "../../../../resources/styles/colors";

.root{
    flex: 0 0 0;
    display: flex;
    align-items: center;
}

.detail {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    padding-left: 1rem;
}

.description {
    display:flex;
    gap: 0.5rem;
    align-items: center;
}

.externalGroups{
    color: @primaryText;
}

