@import "../../resources/styles/colors.less";
@import "../../resources/styles/variables.less";

@sidebarMediumBreakpoint: 500px;
@sidebarLargeBreakpoint: 850px;

.resources {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.resourceLinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.fullWidthSm {
    display: grid;
    grid-column: auto / span 2;
}

.supportForumTop {
    display: none;
}

.customSupportLink {
    display: grid;
    grid-column: auto / span 2;
}

.resourcesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.resourcesHeading {
    margin: 0;
    font-weight: @bold;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.1px;
    font-size: 0.875rem;
    color: var(--colorTextSecondary);
}

.showHideButton {
    border: none;
    background-color: transparent;
    color: @mainText;
    font: @textRegularBoldSmall;
    cursor: pointer;

    &:hover {
        color: @linkTextHover;
    }
}

.moreResources {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s cubic-bezier(0.25, 0.46, 0.28, 1.01);
}

.moreResourcesInner {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    grid-row: 1 / span 2;
}

@container (min-width: @sidebarLargeBreakpoint) {
    .fullWidthSm {
        grid-column: auto;
    }
}

@container (min-width: @sidebarLargeBreakpoint) {
    .resourceLinks {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .supportForumTop {
        display: grid;
    }
    .supportForumBottom {
        display: none;
    }
    .customSupportLink {
        grid-column: auto;
    }
    .moreResourcesInner {
        grid-column: auto / span 3;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
