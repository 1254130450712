@import "../../resources/styles/media";
@import "../../resources/styles/variables";
@import "../../resources/styles/colors";

.textControl {
  flex: 1 1 auto;
}

.textControlKey {
  max-width: 200/16rem;
}

.separator {
  margin: 0 2rem;
  font-weight: @semibold;
  align-self: center;
}

.errorText {
  color: @dangerText;
}

.itemContainer {
  display: flex;
  align-items: flex-start;
}

// TODO: is this the solution? Three items doesn't fix so well horizontally, so forcing them into a column seems better.
.threeItemContainer {
  flex-direction: column;
}

@media (max-width: @screen-md) {
  .textControl {
    width:100%;
  }

  .itemContainer {
    flex-direction: column;
  }

  .separator {
    display: none;
  }
}
