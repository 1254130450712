@import "../../../../../resources/styles/colors";

.scopeRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 20/16rem;

    h4 {
        color: @ternaryText;
    }
}

.scopeRow > * {
    max-width: 250/16rem;
    min-width: 150/16rem;
    flex: 1 1 150/16rem;
}

.showAll {
    margin-top: 0.5rem;
}
