.narrow {
    max-width: 8rem;
}

.checkboxGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.checkbox {
    position: relative;
    overflow: visible;
    display: table;
    height: auto;
    min-width: 6.875rem;
}