@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";


.dashboardFilterInputClassName {
    font-size: 0.8125rem !important;
    width: 15rem !important;
}

span[role="progressbar"] {
    margin-top: 1rem;
    display: block;
}

.cardContentContainerFilter {
    margin: -1rem 1rem 0 0;
}

.cardContentContainerRows {
    flex: 1;
    overflow-y: auto;
    padding-right: 1rem;
}

.environmentRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;

    .environmentIcon {
        display: flex;
        min-width: 1.5rem;
        padding-right: 0.4rem;
    }
    .environmentIcon {
        svg {
            width: 18px !important;
            height: 16px !important;
        }
    }
    .environmentName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .environmentMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.workerpoolRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    .workerpoolIcon {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .workerpoolIcon {
        svg {
            width: 18px !important;
            height: 16px !important;
        }
    }
    .workerpoolName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .workerpoolMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.endpointRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0.4rem;
    .endpointIcon {
        display: flex;
        min-width: 1rem;
        margin-right: 0.4rem;
        background-color: @whiteConstant;
        border-radius: 1.875rem;
        padding: 0.25rem;

        img {
            width: 1.5rem;
        }
    }
    .endpointName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .endpointMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.healthStatusRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0.4rem;
    .healthStatusIconContainer {
        background: @logoBackground;
        border-radius: 30px;
        margin-right: 0.5rem;
        border: 0.0625rem solid @logoBackground;
        img, svg {
            display: flex;
            min-width: 1rem;
        }
    }
    .healthStatusName {
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .healthStatusMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.tenantRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0;
    .tenantIcon {
        display: flex;
        min-width: 1.5rem;
        padding-right: 0.4rem;
    }
    .tenantIcon {
        svg {
            width: 18px !important;
            height: 16px !important;
        }
    }
    .tenantName {
        padding-right: 0.4rem;
    }
    .tenantMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}

.tenantTagRowsOuterContainer {
    margin-bottom: 1rem;
    h5 {
        color: @primaryText;
        margin: 0.25rem 0;
        padding: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.tenantTagRowsContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-bottom: 0;
    .tenantTagName {
        padding-right: 0.4rem;
    }
    .tenantTagMachinesCount {
        margin: 0.25rem 0;
        margin-left: auto;
    }
}
