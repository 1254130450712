@import "../../../../resources/styles/colors";

.featureToggleTargetingButton {
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
}

.featureToggleTargetingPopoverContainer {
    margin: 0.75rem;

    p {
        font-size: 0.875rem;
    }
}

.featureToggleTargetingIcon {
    padding-left: 5px;
}