@import "../../../resources/styles/media";
@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.dataTable {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1rem;
}

.dataTableHeader .dataTableRow {
    border-top: none;
    &:hover,
    &:focus {
        background-color: transparent;
    }
}

.dataTableHeaderColumn {
    color: @secondaryText;
    padding: 0 1.125rem 0.75rem 1.125rem;
    line-height: 1rem;
    font-weight: @normal;
    padding-bottom: 1rem;
    padding-top: 1rem;
    box-sizing: border-box;
}

.dataTableHeaderColumn {
    text-align: left;
}

.dataTableRowHeaderColumn {
    vertical-align: top;
    padding-top: 0.875rem;
    text-align: right;
    width: 16rem;
}

.dataTableRowHeaderColumnInSidebar {
    width: 8rem !important;
}

.dataTableFooterColumn {
    color: @secondaryText;
    padding: 0;
    line-height: 1rem;
    font-weight: @normal;
    padding-bottom: 1rem;
    text-align: left;
    box-sizing: border-box;
}

.dataTableRow {
    border-top: 0.0625rem solid @dividerLight;
    &:last-child {
        border-bottom: 0.0625rem solid @dividerLight;
    }
    &.noBorder {
        border: none;
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
    &.disabled {
        opacity: 0.4;
    }
}

.dataTableRowColumn {
    box-sizing: border-box;
    color: @primaryText;
    vertical-align: top;
    padding: 0.75rem 1.125rem;
    &:first-child {
        font-weight: @semibold;
    }
}

.dataTableRowColumn a {
    text-align: left;
    color: @mainText
}

@media (max-width: @screen-sm) {
    .dataTable,
    .dataTableHeader,
    .dataTableBody,
    .dataTableHeaderColumn,
    .dataTableFooterColumn,
    .dataTableRow,
    .dataTableRowColumn {
        display: block;
    }
    .dataTable {
        border: none;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    .dataTableHeader .dataTableRow:not(.dataTableToolsRow) {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .dataTableRowColumn {
        border: none;
        position: relative;
        line-height: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0;
        max-width: 100%;
    }
    .dataTableRowHeaderColumn {
        padding: 0.75rem 1.125rem;
        text-align: left;
        width: unset;
    }
    .dataTableRowHeaderColumnInSidebar {
        width: unset;
    }
    .dataTableRowColumn:first-child,
    .dataTableRowColumn:not(:first-child) {
        width: 100%;
    }
    .dataTableRowColumn:not(:first-child) {
        padding-top: 0;
    }
}
