.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.name {
    min-width: 0; // https://stackoverflow.com/questions/31069474/text-overflow-in-combination-with-flexbox-not-working
    flex-basis: 0; // Required to get the above min-width: 0 working in IE11
    flex-grow: 1;
    flex-shrink: 1;
}
