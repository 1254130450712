.environmentChips {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.projectNameCell {
    width: 30%;
}
.environmentNameCell {
    width: 60%;
}

.overflowMenu {
    display: flex;
    justify-content: flex-end;
}
