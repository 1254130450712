@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.machineIcon {
    width: 2.438rem;
    height: 2.563rem; // Always set height or the screen gets janky as images load.
}

.machineRow {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    margin-left: 1.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 0.75rem;
    }
    .machineImage {
        margin-right: 0.5rem;
        background-color: @logoBackground;
        border-radius: 3.125rem;
        padding: 0.5rem;
        width: 3.125rem;
        height: 3.125rem;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .machineNameContainer {
        width: 13.44rem;
        min-width: 13.44rem;
        max-width: 13.44rem;
        .machineName {
            font-weight: @semibold;
            color: @primaryText;
            padding-right: 0.5rem;
            padding-bottom: 0.1rem;
        }
        .machineType {
            font-size: 0.6875rem;
            font-weight: @semibold;
            color: @secondaryText;
            padding-right: 1rem;
        }
        .machineSummary {
            font-size: 0.6875rem;
            color: @secondaryText;
            padding-right: 1rem;
        }
    }
    .healthStatusIconContainer {
        margin-right: 3rem;
        margin-left: 1rem;
    }

    .chipContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
}
