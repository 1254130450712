@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.select {
    width: 100% !important;
    min-width: 0 !important;
    overflow: visible; // Needed to display the floating labels/error messages associated with controls.
    display: flex;
    flex-direction: column;
}

.filter {
    margin: 0 1rem;
}

.container {
    width: 100%;
}

.empty {
    padding: 1rem;
}

.visuallyHidden {
    .visuallyHiddenButAccessible();
}
.secondaryText {
    color: @secondaryText;
    font-size: 0.75rem;
}

.menuListScrollContainer {
    min-width: 150px;
    max-height: 400px;
    overflow-y: auto;
}

.menuItemContent {
    display: flex;
    width: 100%;
    align-items: center;

    &.danger {
        color: @dangerText;
    }
}

.menuItemContentIcon {
    width: auto;
    margin-right: 1rem;
}
