@import "../../resources/styles/colors.less";

.root:global(.MuiChip-root) {
  line-height: 1.5rem;
  height: 1.5rem; // Specify height explicitly, or svg icons can push the container height out (eg. if you display
  // multiple chips in a row where some do/don't contain an avatar. E.g. environments list).
  max-width: 100%;
  display: inline-flex;
  background-color: @chipBackground;
  vertical-align: middle;
  // This color affects the text color of the label through inheritance
  // We have to set it here so that it can be overridden through the style prop on the _root_ chip element from material-ui
  // Since this is the only element that we can affect using the style prop
  opacity: 1;

  &:focus-within {
    outline: 2px solid @focusIconButton;
  }
}

.root-margin:global(.MuiChip-root) {
  margin: 4px;
}

.root-no-margin:global(.MuiChip-root) {
  margin: 0;
}

.label:global(.MuiChip-label) {
  flex: 1;
}