@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables.less";

.deploymentsTableRow {
    font-weight: @semibold;
}

.deploymentsTableRow td:last-child {
    padding-bottom: 0.5rem;
}

.phaseHeader {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: @semibold;
}

.phaseHeader small {
    font-size: 0.75rem;
    margin-left: 0.1875rem;
    color: @secondaryText;
    font-weight: @semibold;
}

.phaseEnvironmentRow td {
    padding-left: 2rem !important;
}

.deploymentRowButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
}

.deploymentExpander {
    margin-left: 10/16rem;
    width: 48/16rem;
}

.deploymentDate {
    font-weight: @normal;
}

.deploymentDuration {
    font-weight: @normal;
    font-size: smaller;
}

.visible {
    display: table-row;
}

.hidden {
    display: none;
}

.taskDetailsContainer {
    display: flex;
    flex-direction: row;
    min-width: 180px;
    justify-content: left;

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 0.5rem;
    }

    .description {
        color: @primaryText;
        font-weight: @semibold;
        font-size: 14/16rem;
        text-align:left;
    }

    .date {
        color: @secondaryText;
        font-weight: @semibold;
        font-size: 0.6875rem;
    }
}

@media (max-width: @screen-sm) {
    .channelLifecycleDetails {
        padding: 0 1rem 0.34rem;
    }
}

.expandCollapse {
    display: flex;
    justify-content: flex-end;
}

.noteCenter {
    display: flex;
    justify-content: center;
}