@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/fonts.less";

.valueCell {
    height: 100%;
}

.deletedText {
    color: @secondaryText !important;
    text-decoration: line-through
}

.deletedSensitive {
    color: @secondaryText !important;
}

.valueCellContentReadonly {
    display: flex;
    align-items: center;
}

.value {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    height: inherit;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

.certificate, .account, .workerPool {
    height: inherit;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:focus {
        outline: none;
    }
}

.certificateLink, .accountLink, .workerPoolLink {
    height: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: @primaryDark;
    .monospaced;
}

.handCursor {
    cursor: pointer;
}

.multilineText {
    padding-top:  14px;
    max-height: 35px;
    padding-bottom: 10px;
    overflow: hidden !important;
}

.multilineTextFocused {
    padding-top:14px !important;
    max-height:300px !important;
}

.multilineContainer {
    white-space: pre;
    width: 100%;
    height: 100%;
}

.monospaced {
    .monospace;
    font-size: @monospace-font-size;
}