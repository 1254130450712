@import "../../../resources/styles/colors.less";

.nameCellContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.spacer {
    flex-grow: 1;
}

.value {
    display: flex;
    align-items: center;
}